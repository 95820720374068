<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待自取"
                    name="40"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="商品编码"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入商品编码"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderType"
                    label="订单类型"
                >
                    <dictionaries-picker
                        type="orderType"
                        v-model="queryFormModel.orderType"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentMode"
                    label="付款类型"
                >
                    <dictionaries-picker
                        type="paymentMode"
                        v-model="queryFormModel.paymentMode"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderSource"
                    label="订单来源"
                >
                    <dictionaries-picker
                        type="orderSource"
                        v-model="queryFormModel.orderSource"
                    />
                </el-form-item>
                <el-form-item
                    key="orderSyncErpStatus"
                    prop="orderSyncErpStatus"
                    label="同步状态"
                >
                    <dictionaries-picker
                        type="orderSyncErpStatus"
                        v-model="queryFormModel.orderSyncErpStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="extendFieldInfo"
                    label="扩展信息"
                >
                    <el-input
                        v-model="queryFormModel.extendFieldInfo"
                        placeholder="请输入扩展信息"
                    />
                </el-form-item>
                <el-form-item
                    prop="pickupDate"
                    label="自取日期"
                >
                    <el-date-picker
                        v-model="queryFormModel.pickupDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="选择自取日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="isWaybillPrinted"
                    label="订单打印状态"
                >
                    <dictionaries-picker
                        type="waybillPrintStatus"
                        v-model="queryFormModel.isWaybillPrinted"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderExcel"
                    >
                        订单导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportGoodsStatExcel"
                    >
                        商品统计
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onBatchSyncErpOrder()"
                        v-show="userData.shop.isPushOrderErp && queryFormModel.orderStatus == '40'"
                    >
                        Erp批量同步
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="onBatchSyncErpOrderTrackingInfo"
                        v-show="userData.shop.isPushOrderErp && (queryFormModel.orderStatus == '40' || queryFormModel.orderStatus == '100')"
                    >
                        Erp同步物流
                    </el-button>
                    <el-button
                        type="success"
                        size="small"
                        icon="el-icon-download"
                        @click="printOrder(null, null)"
                    >
                        打印订单
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column type="expand">
                    <template slot-scope="order">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                        >
                            <el-table-column
                                label="商品图片"
                                width="120"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        :data="scope.row.specImageUrl"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                label="商品规格"
                                min-width="100"
                            />
                            <el-table-column
                                prop="finalPrice"
                                label="商品价格"
                                min-width="50"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                min-width="50"
                            />
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSn"
                    label="订单编号"
                    width="150"
                >
                    <template slot-scope="scope">
                        <ImageList
                            class="dp-ib"
                            :data="scope.row.buyerLevelIconUrl"
                            item-width="20px"
                        />
                        {{ scope.row.orderSn }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="下单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="payableAmount"
                    label="订单金额"
                    width="70"
                >
                    <template
                        slot-scope="scope"
                    >
                        <el-popover
                            trigger="hover"
                        >
                            <el-table :data="scope.row.amountInfoList">
                                <el-table-column
                                    width="150px"
                                    property="label"
                                />
                                <el-table-column
                                    width="100px"
                                    property="value"
                                />
                            </el-table>
                            <span
                                slot="reference"
                                class="tc-blue"
                            >{{ scope.row.payableAmount }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <!--                <el-table-column
            prop="orderSourceName"
            label="订单来源"
            width="70"
        />
        <el-table-column
            prop="orderTypeName"
            label="订单类型"
            width="70"
        />
        <el-table-column
            prop="paymentModeName"
            label="付款类型"
            width="70"
        />-->
                <el-table-column
                    label="下单人"
                    min-width="100"
                >
                    <template slot-scope="order">
                        +{{ order.row.buyerCountryCode }}-{{ order.row.buyerMobile }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="代理商"
                    min-width="100"
                >
                    <template slot-scope="order">
                        <div
                            v-if="order.row.isDistributor"
                            class="distributor"
                        >
                            代理商：
                            {{ order.row.distributorName }} <span class="tc-primary">+{{ order.row.distributorCountryCode }}-{{ order.row.distributorTel }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="pickupStoreName"
                    label="门店名称"
                    width="70"
                />
                <el-table-column
                    label="自取信息"
                    width="120"
                >
                    <template slot-scope="scope">
                        <div>自取类型：{{ scope.row.pickupTypeName }}</div>
                        <div v-if="scope.row.pickupDate">自取日期：{{ scope.row.pickupDate | moment('YYYY-MM-DD') }}</div>
                        <div v-if="scope.row.pickupTime">自取时间：{{ scope.row.pickupTime }}</div>
                        <div v-if="scope.row.pickupTel">自取电话：{{ scope.row.pickupTel }}</div>
                        <div v-if="scope.row.storeTableArea">桌位区域：{{ scope.row.storeTableArea }}</div>
                        <div v-if="scope.row.storeTableSn">桌位编号：{{ scope.row.storeTableSn }}</div>
                        <div v-if="scope.row.pickupNumber">取餐号：{{ scope.row.pickupNumber }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="自取日期"
                    width="85"
                >
                    <template slot-scope="scope">
                        {{ scope.row.pickupDate | moment('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="pickupTime"
                    label="自取时间"
                    width="90"
                />
                <el-table-column
                    prop="orderStatusName"
                    label="订单状态"
                    width="70"
                />
                <el-table-column
                    prop="waybillPrintStatusName"
                    label="订单打印状态"
                    width="80"
                />
                <el-table-column
                    prop="buyerMsg"
                    label="买家留言"
                    min-width="100"
                />
                <el-table-column
                    label="同步状态"
                    width="90"
                    v-if="userData.shop.isPushOrderErp"
                >
                    <template slot-scope="order">
                        {{ order.row.orderSyncErpStatusName }}
                        <div v-if="order.row.orderSyncErpStatus === 100">
                            {{ order.row.orderSyncErpTime| moment('YYYY-MM-DD HH:mm:ss') }}
                        </div>
                    </template>
                </el-table-column>
                <!--                <el-table-column
            label="扩展信息"
            min-width="100"
        >
            <template
                slot-scope="scope"
                v-if="scope.row.extendFieldInfoList && scope.row.extendFieldInfoList.length != 0"
            >
                <el-popover
                    trigger="hover"
                >
                    <el-table :data="scope.row.extendFieldInfoList">
                        <el-table-column
                            width="150px"
                            property="label"
                            label="字段名称"
                        />
                        <el-table-column
                            width="200px"
                            property="value"
                            label="字段值"
                        />
                    </el-table>
                    <span
                        slot="reference"
                        class="tc-blue"
                    >{{ scope.row.extendFieldInfoList[0].label }}:{{ scope.row.extendFieldInfoList[0].value }}</span>
                </el-popover>
            </template>
        </el-table-column>-->
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/order/order/storeOrderDetail/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="printOrder(order.row, order.row.isWaybillPrinted)"
                        >
                            打印订单
                        </el-button>
                        <template v-if="userData.user.appId === 2">
                            <el-button
                                type="text"
                                size="small"
                                @click="onBatchSyncErpOrder(scope.row.id)"
                                v-show="queryFormModel.orderStatus == '40' && userData.shop.isPushOrderErp && (scope.row.orderSyncErpStatus === 10 || scope.row.orderSyncErpStatus === 98)"
                            >
                                Erp同步
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="completeOrder(scope.row)"
                                v-if="scope.row.orderStatus === 40"
                            >
                                完成订单
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="cancelOrder(scope.row)"
                                v-if="scope.row.orderStatus !== 99 && scope.row.isSettlement !== 1"
                            >
                                取消订单
                            </el-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--取消订单-->
        <el-dialog
            title="取消订单"
            center
            width="600px"
            :visible.sync="cancelDialog.isVisible"
            @closed="onCancelDialogCancel"
        >
            <el-form
                ref="cancelForm"
                size="small"
                :model="cancelDialog.formModel"
                :rules="cancelDialog.formRules"
            >
                <el-form-item
                    prop="cancelDescOther"
                    label="取消原因"
                    label-width="6em"
                    v-if="cancelDialog.type === 2"
                >
                    <el-input
                        type="textarea"
                        v-model="cancelDialog.formModel.cancelDescOther"
                        auto-complete="off"
                    />
                </el-form-item>
                <template v-if="cancelDialog.type === 1">
                    <el-form-item
                        prop="cancelDesc"
                        label="取消原因"
                        label-width="6em"
                    >
                        <div>
                            <el-radio
                                class="ma-t ma-l"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(身份证信息校验失败)"
                                border>海关退单(身份证信息校验失败)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(超个人年度购买限额)"
                                border>海关退单(超个人年度购买限额)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(购买人与支付人身份证信息不一致)"
                                border>海关退单(购买人与支付人身份证信息不一致)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(风控异常退单)"
                                border>海关退单(风控异常退单)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="其他"
                                border>其他
                            </el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item
                        prop="cancelDescOther"
                        label="其他原因"
                        label-width="6em"
                        v-if="cancelDialog.formModel.cancelDesc == '其他'"
                    >
                        <el-input
                            type="textarea"
                            v-model="cancelDialog.formModel.cancelDescOther"
                            auto-complete="off"
                        />
                    </el-form-item>
                </template>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCancelDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCancelDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'StoreOrder',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                orderStatus: '40',
                goodsName: '',
                skuSn: '',
                orderType: '',
                paymentMode: '',
                shipMethod: '2',
                buyerLevelId: '',
                pickupDate: '',
                extendFieldInfo: '',
                orderTime: '',
                goodsTypeNE: '',
                goodsType: '1',
                orderSource: '',
                orderSyncErpStatus: '',
                isWaybillPrinted: '',
                isShowStoreOrder: '1',
            },
            // 取消订单
            cancelDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cancelDesc: '',
                    cancelDescOther: '',
                },
                // 表单校验规则
                formRules: {
                    cancelDesc: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                    cancelDescOther: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                },
                type: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 等级列表
            buyerLevelList: [],
        };
    },
    methods: {
        printOrder(row, isWaybillPrinted) {
            let ids;
            let orderSns;
            if (row) {
                // 单条
                ids = row.id;
                if (+row.isWaybillPrinted === 1 || +row.isWaybillPrinted === 2) {
                    orderSns = row.orderSn;
                    isWaybillPrinted = 1;
                }
            } else {
                // 多条
                const idList = [];
                const orderSnList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                    if (+item.isWaybillPrinted === 1 || +item.isWaybillPrinted === 2) {
                        orderSnList.push(item.orderSn);
                        isWaybillPrinted = 1;
                    }
                });
                ids = idList.join(',');
                orderSns = orderSnList.join(',');
            }
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            let text = '';
            if (isWaybillPrinted === 1 || isWaybillPrinted === 2) {
                text = `订单号：${orderSns}已打印，是否确定重新打印！`;
            } else {
                text = '是否确定打印订单';
            }
            const { printerType } = this.userData;
            this.$confirm(text, '温馨提示', {
                type: 'warning',
            }).then(() => {
                if (printerType === 1) {
                    this.$api.Ex.Order.printOrderByClodop({ ids, templateType: 2 }).then(json => {
                        const res = json.data;
                        if (res.success) {
                            this.orderLodopPrint(json.data.data, ids);
                        } else {
                            this.$alert(res.msg, '错误提示', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: '确定',
                            });
                        }
                    });
                } else if (printerType === 2) {
                    this.$api.Ex.Order.printOrderByXpyun({ ids }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    });
                }
            });
        },
        orderLodopPrint(res, ids) {
            const { waybillTemplate } = res;
            const { data } = res;
            const templateList = [];
            data.forEach(item => {
                let tpl = waybillTemplate;
                Object.keys(item).forEach(key => {
                    const rex = new RegExp(`\\$\\{${key}\\}`, 'g');
                    const val = JSON.stringify(item[key]);
                    tpl = tpl.replace(rex, val.slice(1, val.length - 1));
                });
                templateList.push(tpl);
            });
            const resultTemplateList = [];
            templateList.forEach(item => {
                resultTemplateList.push(item.replace('LODOP.PRINT_INIT("");', ''));
            });
            /* const lodop = `LODOP.PRINT_INIT("");${templateList.join(
                'LODOP.NewPage();',
            )}LODOP.SET_PRINT_MODE('AUTO_CLOSE_PREWINDOW',1);`; */
            const lodop = `${resultTemplateList.join('LODOP.NewPage();')}`;
            this.$lodop.init(lodop).then(({ LODOP, CLODOP }) => {
                CLODOP.On_Return = (taskId, value) => {
                    if (value === 0) {
                        return;
                    }
                    this.$api.Ex.Order.updateOrderPrintStatus({ ids, status: 1 }).then(json => {
                        const res1 = json.data;
                        this.$message({
                            message: res1.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    });
                };
                LODOP.PREVIEW();
            });
        },
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.orderList(queryData).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: this.$t('pageKey978', '包装费'),
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '手续费',
                        value: item.paymentPoundage,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // 完成订单
        completeOrder(row) {
            this.$confirm('是否确定要完成该订单！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Order.completeOrder({ orderId: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        // 取消订单
        cancelOrder(row) {
            this.cancelDialog.formModel.id = row.id;
            if (row.goodsSendMode === 2 || row.goodsSendMode === 3 || row.goodsSendMode === 4) {
                this.cancelDialog.type = 1;
            } else {
                this.cancelDialog.type = 2;
                this.cancelDialog.formModel.cancelDesc = '其他';
            }
            this.cancelDialog.isVisible = true;
        },
        onCancelDialogConfirm() {
            const { cancelForm } = this.$refs;
            cancelForm.validate().then(() => {
                this.$api.Ex.Order.cancelOrder({
                    ...this.cancelDialog.formModel,
                    cancelDesc:
                        this.cancelDialog.formModel.cancelDesc === '其他'
                            ? this.cancelDialog.formModel.cancelDescOther
                            : this.cancelDialog.formModel.cancelDesc,
                    isSendMsg: this.cancelDialog.formModel.cancelDesc !== '其他',
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.cancelDialog.isVisible = false;
                });
            });
        },
        onCancelDialogCancel() {
            const { cancelForm } = this.$refs;
            cancelForm.resetFields();
            const { formModel } = this.cancelDialog;
            formModel.id = '';
            this.cancelDialog.isVisible = false;
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        // excel导出
        exportOrderExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            this.$http.href('/ex/order/exportOrderExcel', {
                ...this.queryFormModel,
                orderBeginTime,
                orderEndTime,
                ids,
            });
        },
        exportGoodsStatExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            this.$http.href('/ex/order/exportGoodsStatExcel', {
                ...this.queryFormModel,
                orderBeginTime,
                orderEndTime,
                ids,
            });
        },
        // erp
        onBatchSyncErpOrder(id) {
            if (!id) {
                const ids = this.currentTableSelect.reduce((prev, next) => {
                    prev.push(next.id);
                    return prev;
                }, []);
                if (ids.length === 0) {
                    this.$message({
                        message: '请选择订单',
                        type: 'error',
                    });
                    return;
                }
                id = ids.join(',');
            }
            this.$confirm('是否确定同步订单！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrder({ ids: id }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
        onBatchSyncErpOrderTrackingInfo() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定同步订单物流信息！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单物流中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrderTrackingInfo({ ids: ids.join(',') }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss">
</style>
