<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    v-if="type === 2"
                    label="待审核"
                    name="orderAudit-20"
                />
                <el-tab-pane
                    v-if="type === 2"
                    label="审核不通过"
                    name="orderAudit-30"
                />
                <el-tab-pane
                    label="待拣货"
                    name="orderDelivery-10"
                />
                <el-tab-pane
                    label="拣货中"
                    name="orderDelivery-20"
                />
                <el-tab-pane
                    label="待接单"
                    name="orderDelivery-30"
                />
                <el-tab-pane
                    label="待取货"
                    name="orderDelivery-40"
                />
                <el-tab-pane
                    label="配送中"
                    name="orderDelivery-50"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    label="收件人"
                >
                    <el-input
                        v-model="queryFormModel.deliveryConsignee"
                        placeholder="请输入收件人"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhone"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="商品编码"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入商品编码"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierName"
                    label="供应商"
                >
                    <el-input
                        v-model="queryFormModel.supplierName"
                        placeholder="请输入供应商"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerMobile"
                    label="下单人电话"
                >
                    <el-input
                        v-model="queryFormModel.buyerMobile"
                        placeholder="请输入下单人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorInfo"
                    label="代理商信息"
                >
                    <el-input
                        v-model="queryFormModel.distributorInfo"
                        placeholder="请输入代理商信息"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderType"
                    label="订单类型"
                >
                    <dictionaries-picker
                        type="orderType"
                        v-model="queryFormModel.orderType"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentMode"
                    label="付款类型"
                >
                    <dictionaries-picker
                        type="paymentMode"
                        v-model="queryFormModel.paymentMode"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderSource"
                    label="订单来源"
                >
                    <dictionaries-picker
                        type="orderSource"
                        v-model="queryFormModel.orderSource"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="confirmShipDate"
                    label="配送日期"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.confirmShipDate"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="confirmShipTime"
                    label="配送时间"
                >
                    <el-input
                        v-model="queryFormModel.confirmShipTime"
                        placeholder="请输入配送时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="isWaybillPrinted"
                    label="订单打印状态"
                >
                    <dictionaries-picker
                        type="waybillPrintStatus"
                        v-model="queryFormModel.isWaybillPrinted"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderExcel"
                    >
                        订单导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportGoodsStatExcel"
                    >
                        商品统计
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="auditOrderBatch"
                        v-show="queryFormModel.orderStatus == 'orderAudit-20'"
                    >
                        批量审核订单
                    </el-button>
                    <!--<el-button
type="success"
size="small"
icon="el-icon-download"
@click="onLodopPrint"
v-show="queryFormModel.orderStatus == '30-2'"
>打印面单
</el-button>-->
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderGoodsImage"
                        v-if="type===2"
                    >
                        批量导出商品图片
                    </el-button>
                    <el-button
                        type="success"
                        size="small"
                        icon="el-icon-download"
                        @click="printOrder(null, null)"
                    >
                        打印订单
                    </el-button>
                </el-button-group>
            </div>

            <!--订单列表-->
            <el-table
                class="order-table"
                ref="table"
                stripe
                border
                size="small"
                :data="tableData"
                :span-method="onSpanMethod"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    class-name="cell-no-pa"
                    label="商品"
                >
                    <template slot-scope="order">
                        <div class="order-header">
                            <div>
                                <span>下单时间：{{ order.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}</span>
                                <span>订单号：{{ order.row.orderSn }}</span>
                            </div>
                            <div
                                class="supplier"
                                v-if="order.row.supplierName">
                                供应商：{{ order.row.supplierName }}
                            </div>
                            <div class="shipping-time">
                                <template v-if="order.row.estimateShippingTime">
                                    预计发货时间：{{ order.row.estimateShippingTime | moment }}&nbsp;&nbsp;
                                </template>
                                <template v-if="order.row.confirmShipDate">
                                    配送时间：{{ order.row.confirmShipDate | moment }} {{ order.row.confirmShipTime }}&nbsp;&nbsp;
                                </template>
                                <template v-if="order.row.shippingTime">
                                    发货时间：{{ order.row.shippingTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </div>
                        </div>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                            :show-header="false"
                            :span-method="onGoodsSpanMethod"
                        >
                            <el-table-column label="商品">
                                <div
                                    class="goods-wrap"
                                    slot-scope="scope"
                                >
                                    <ImageList :data="scope.row.specImageUrl"/>
                                    <div class="body">
                                        <div class="label">
                                            {{ scope.row.goodsName }}
                                        </div>
                                        <div class="spec">
                                            {{ scope.row.specDesc }}
                                        </div>
                                        <div
                                            class="badge-wrap"
                                        >
                                            <div
                                                class="badge"
                                                v-if="order.row.isPresellOrder"
                                            >
                                                预
                                            </div>
                                            <div
                                                class="badge"
                                                v-if="scope.row.goodsPremiumAmount"
                                            >
                                                保
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-table-column>
                            <el-table-column
                                prop="finalPrice"
                                label="单价"
                                width="100"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                width="100"
                            />
                            <el-table-column
                                label="订单金额"
                                width="100"
                            >
                                <template v-slot>
                                    <el-popover
                                        trigger="hover"
                                    >
                                        <el-table
                                            :show-header="false"
                                            :data="order.row.amountInfoList"
                                        >
                                            <el-table-column
                                                width="150px"
                                                property="label"
                                            />
                                            <el-table-column
                                                width="100px"
                                                property="value"
                                            />
                                        </el-table>
                                        <span
                                            slot="reference"
                                            class="tc-blue"
                                        >{{ order.row.payableAmount }}</span>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="配送员"
                                width="100"
                            >
                                <template v-slot>
                                    {{ order.row.driverName }}
                                    <div>{{ order.row.driverMobile }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="waybillPrintStatusName"
                                label="订单打印状态"
                                width="100"
                            >
                                {{ order.row.waybillPrintStatusName }}
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                width="100"
                            >
                                <div class="table-ctrl">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="$router.push((order.row.isPrivateCustom ? '/order/order/customOrderDetail/' : '/order/order/otoldOrderDetail/') + order.row.id)"
                                    >
                                        查看
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="printOrder(order.row, order.row.isWaybillPrinted)"
                                    >
                                        打印订单
                                    </el-button>
                                    <template
                                        v-if="((userData.user.appId === 2 && (!order.row.storeId || order.row.isStoreAgentSell))
                                            || (userData.user.appId === 3 && order.row.storeId)) && (!order.row.isPrivateCustom || order.row.orderAuditStatus === 100)"
                                    >
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="completeOrder(order.row)"
                                            v-if="(order.row.orderStatus === 20 && order.row.goodsType !== 1) || (order.row.orderStatus === 30 && order.row.goodsType === 1)"
                                        >
                                            完成订单
                                        </el-button>
                                        <el-button
                                            type="text"
                                            size="small"
                                            @click="cancelOrder(order.row)"
                                            v-if="order.row.orderStatus !== 99 && order.row.isSettlement !== 1 && order.row.isRefunding == 0"
                                        >
                                            取消订单
                                        </el-button>
                                    </template>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="auditOrder(order.row.id)"
                                        v-if="queryFormModel.orderStatus == 'orderAudit-20' && order.row.orderAuditStatus === 20"
                                    >
                                        审核
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="orderComments(order.row)"
                                    >
                                        平台留言
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="orderLog(order.row)"
                                    >
                                        售后备注
                                    </el-button>
                                </div>
                            </el-table-column>
                        </el-table>
                        <div class="order-footer">
                            <div class="left">
                                <div class="address">
                                    收件信息：{{ order.row.deliveryConsignee }} <span class="tc-primary">{{ order.row.deliveryPhone }}</span> {{ order.row.deliveryRegionAddress }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="买家留言"
                                >
                                    买家留言：{{ order.row.buyerMsg }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="平台留言"
                                >
                                    平台留言：{{ order.row.orderComments }}
                                </div>
                                <div
                                    v-if="!!order.row.orderAuditStatus"
                                    class="buyer-msg"
                                    title="订单审核"
                                >
                                    <template v-if="order.row.orderAuditStatus === 30">
                                        不通过原因：{{ order.row.auditOpinion }}
                                    </template>
                                    <template v-if="order.row.orderAuditStatus !== 20">
                                        审核人：{{ order.row.auditOperatorName }} 审核时间：{{ order.row.auditTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </div>
                                <div
                                    class="buyer-msg"
                                    v-if="order.row.orderTagList && order.row.orderTagList.length !== 0"
                                >
                                    <el-tag
                                        v-for="item in order.row.orderTagList"
                                        :key="item"
                                        type="danger">{{ item }}
                                    </el-tag>
                                </div>
                            </div>
                            <div class="right">
                                <div class="inner">
                                    <div
                                        class="distributor"
                                    >
                                        下单人：
                                        <span class="tc-primary">+{{ order.row.buyerCountryCode }}-{{ order.row.buyerMobile }}</span>
                                    </div>
                                    <div
                                        v-if="order.row.isDistributor"
                                        class="distributor"
                                    >
                                        代理商：
                                        {{ order.row.distributorName }} <span class="tc-primary">+{{ order.row.distributorCountryCode }}-{{ order.row.distributorTel }}</span>
                                    </div>
                                    <div
                                        v-if="order.row.storeName"
                                        class="distributor"
                                    >
                                        门店：
                                        {{ order.row.storeName }}
                                    </div>
                                    <div
                                        v-if="order.row.extendFieldInfoList && order.row.extendFieldInfoList.length != 0"
                                        class="extra"
                                    >
                                        扩展信息：
                                        <el-popover
                                            trigger="hover"
                                        >
                                            <el-table :data="order.row.extendFieldInfoList">
                                                <el-table-column
                                                    width="150px"
                                                    property="label"
                                                    label="字段名称"
                                                />
                                                <el-table-column
                                                    width="200px"
                                                    property="value"
                                                    label="字段值"
                                                />
                                            </el-table>
                                            <span
                                                slot="reference"
                                                class="tc-blue"
                                            >{{ order.row.extendFieldInfoList[0].label }}:{{ order.row.extendFieldInfoList[0].value }}</span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    label="单价"
                />
                <el-table-column
                    width="100"
                    label="数量"
                />
                <el-table-column
                    width="100"
                    label="订单金额"
                />
                <el-table-column
                    width="100"
                    label="配送员"
                />
                <el-table-column
                    width="100"
                    label="订单打印状态"
                />
                <el-table-column
                    width="100"
                    label="操作"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--取消订单-->
        <el-dialog
            title="取消订单"
            center
            width="600px"
            :visible.sync="cancelDialog.isVisible"
            @closed="onCancelDialogCancel"
        >
            <el-form
                ref="cancelForm"
                size="small"
                :model="cancelDialog.formModel"
                :rules="cancelDialog.formRules"
            >
                <el-form-item
                    prop="cancelDescOther"
                    label="取消原因"
                    label-width="6em"
                    v-if="cancelDialog.type === 2"
                >
                    <el-input
                        type="textarea"
                        v-model="cancelDialog.formModel.cancelDescOther"
                        auto-complete="off"
                    />
                </el-form-item>
                <template v-if="cancelDialog.type === 1">
                    <el-form-item
                        prop="cancelDesc"
                        label="取消原因"
                        label-width="6em"
                    >
                        <div>
                            <el-radio
                                class="ma-t ma-l"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(身份证信息校验失败)"
                                border>海关退单(身份证信息校验失败)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(超个人年度购买限额)"
                                border>海关退单(超个人年度购买限额)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(购买人与支付人身份证信息不一致)"
                                border>海关退单(购买人与支付人身份证信息不一致)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="海关退单(风控异常退单)"
                                border>海关退单(风控异常退单)
                            </el-radio>
                            <el-radio
                                class="ma-t"
                                v-model="cancelDialog.formModel.cancelDesc"
                                label="其他"
                                border>其他
                            </el-radio>
                        </div>
                    </el-form-item>
                    <el-form-item
                        prop="cancelDescOther"
                        label="其他原因"
                        label-width="6em"
                        v-if="cancelDialog.formModel.cancelDesc == '其他'"
                    >
                        <el-input
                            type="textarea"
                            v-model="cancelDialog.formModel.cancelDescOther"
                            auto-complete="off"
                        />
                    </el-form-item>
                </template>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCancelDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCancelDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!--平台留言-->
        <el-dialog
            title="平台留言"
            center
            width="600px"
            :visible.sync="orderCommentsDialog.isVisible"
            @closed="onOrderCommentsDialogCancel"
        >
            <el-form
                ref="orderCommentsForm"
                size="small"
                :model="orderCommentsDialog.formModel"
                :rules="orderCommentsDialog.formRules"
            >
                <el-form-item
                    prop="orderComments"
                    label="平台留言"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="orderCommentsDialog.formModel.orderComments"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onOrderCommentsDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onOrderCommentsDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!-- 打印面单 -->
        <el-dialog
            title="打印面单"
            center
            width="600px"
            :visible.sync="lodopPrintDialog.isVisible"
            @closed="onLodopPrintDialogCancel"
        >
            <el-form
                ref="lodopPrintForm"
                size="small"
                :model="lodopPrintDialog.formModel"
                :rules="lodopPrintDialog.formRules"
            >
                <el-form-item
                    key="waybillTemplateId"
                    prop="waybillTemplateId"
                    label="运单模板"
                    label-width="8em"
                >
                    <el-select
                        v-model="lodopPrintDialog.formModel.waybillTemplateId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in lodopPrintDialog.templateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onLodopPrintDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onLodopPrintDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!--订单审核-->
        <el-dialog
            title="订单审核"
            center
            width="800px"
            :visible.sync="auditDialog.isVisible"
            @closed="onAuditDialogCancel"
        >
            <el-form
                ref="auditForm"
                size="small"
                :model="auditDialog.formModel"
                :rules="auditDialog.formRules"
            >
                <el-form-item
                    prop="orderAuditStatus"
                    label="审核是否通过:"
                    label-width="9em"
                >
                    <el-select
                        v-model="auditDialog.formModel.orderAuditStatus"
                        placeholder="请选择"
                    >
                        <el-option
                            :key="30"
                            label="审核不通过"
                            :value="30"
                        />
                        <el-option
                            :key="100"
                            label="审核通过"
                            :value="100"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="auditOpinion"
                    label="审核不通过原因:"
                    label-width="9em"
                    v-if="auditDialog.formModel.orderAuditStatus === 30"
                >
                    <el-input
                        type="textarea"
                        v-model="auditDialog.formModel.auditOpinion"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAuditDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAuditDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!--售后备注-->
        <el-dialog
            title="售后备注"
            center
            width="600px"
            :visible.sync="orderLogDialog.isVisible"
            @closed="onOrderLogDialogCancel"
        >
            <el-form
                ref="orderLogForm"
                size="small"
                :model="orderLogDialog.formModel"
                :rules="orderLogDialog.formRules"
            >
                <el-form-item
                    prop="orderLog"
                    label="售后备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="orderLogDialog.formModel.orderLog"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onOrderLogDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onOrderLogDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!--完成单单-->
        <el-dialog
            title="完成订单"
            center
            width="600px"
            :visible.sync="completeOrderDialog.isVisible"
            @closed="onCompleteOrderDialogCancel"
        >
            <el-form
                ref="completeOrderForm"
                size="small"
                :model="completeOrderDialog.formModel"
                :rules="completeOrderDialog.formRules"
            >
                <el-form-item
                    prop="receiveRemarks"
                    label="签收备注"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="completeOrderDialog.formModel.receiveRemarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="receiveAttachment"
                    label="签收附件"
                    label-width="6em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="completeOrderDialog.formModel.receiveAttachment"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCompleteOrderDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCompleteOrderDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <Lodop
            ref="Lodop"
            v-model="lodop"
        />
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Order',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                orderStatus: 'orderDelivery-10',
                goodsName: '',
                skuSn: '',
                orderType: '',
                paymentMode: '',
                shipMethod: '4',
                buyerLevelId: '',
                pickupDate: '',
                extendFieldInfo: '',
                orderTime: '',
                goodsTypeNE: '',
                goodsType: '1',
                orderSource: '',
                supplierName: '',
                distributorInfo: '',
                trackingNumber: '',
                estimateShippingTimeStr: '',
                isGoodsPremium: '',
                orderSyncErpStatus: '',
                buyerMobile: '',
                goodsSendMode: '',
                isCheckIdCard: '',
                goodsSendModeNE: 4,
                confirmShipDate: '',
                confirmShipTime: '',
                isWaybillPrinted: '',
            },
            // 取消订单
            cancelDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cancelDesc: '',
                    cancelDescOther: '',
                },
                // 表单校验规则
                formRules: {
                    cancelDesc: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                    cancelDescOther: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                },
                type: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 等级列表
            buyerLevelList: [],
            // 快递公司列表
            shopExpressList: [],
            // 平台留言
            orderCommentsDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderComments: '',
                },
                // 表单校验规则
                formRules: {},
            },
            loading: '',
            lodop: '',
            // 打印面单
            lodopPrintDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    waybillTemplateExpressId: '',
                    waybillTemplateId: '',
                },
                // 表单校验规则
                formRules: {
                    waybillTemplateId: {
                        required: true,
                        message: '请选择模板ID',
                    },
                },
                templateList: [],
            },
            // 订单审核
            auditDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    orderAuditStatus: '',
                    auditOpinion: '',
                },
                // 表单校验规则
                formRules: {
                    orderAuditStatus: {
                        required: true,
                        message: '请选择审核结果',
                    },
                    auditOpinion: {
                        required: true,
                        message: '请输入不通过原因',
                        trigger: 'blur',
                    },
                },
            },
            // 售后备注
            orderLogDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderLog: '',
                },
                // 表单校验规则
                formRules: {
                    orderLog: {
                        required: true,
                        message: '请输入售后备注',
                        trigger: 'blur',
                    },
                },
            },
            // 送达签收
            completeOrderDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    receiveRemarks: '',
                    receiveAttachment: [],
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    computed: {
        tableColumn() {
            return this.$refs.table.columns.length - 1;
        },
    },
    methods: {
        printOrder(row, isWaybillPrinted) {
            let ids;
            let orderSns;
            if (row) {
                // 单条
                ids = row.id;
                if (+row.isWaybillPrinted === 1 || +row.isWaybillPrinted === 2) {
                    orderSns = row.orderSn;
                    isWaybillPrinted = 1;
                }
            } else {
                // 多条
                const idList = [];
                const orderSnList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                    if (+item.isWaybillPrinted === 1 || +item.isWaybillPrinted === 2) {
                        orderSnList.push(item.orderSn);
                        isWaybillPrinted = 1;
                    }
                });
                ids = idList.join(',');
                orderSns = orderSnList.join(',');
            }
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            let text = '';
            if (isWaybillPrinted === 1 || isWaybillPrinted === 2) {
                text = `订单号：${orderSns}已打印，是否确定重新打印！`;
            } else {
                text = '是否确定打印订单';
            }
            const { printerType } = this.userData;
            this.$confirm(text, '温馨提示', {
                type: 'warning',
            }).then(() => {
                if (printerType === 1) {
                    this.$api.Ex.Order.printOrderByClodop({ ids, templateType: 1 }).then(json => {
                        const res = json.data;
                        if (res.success) {
                            this.orderLodopPrint(json.data.data, ids);
                        } else {
                            this.$alert(res.msg, '错误提示', {
                                dangerouslyUseHTMLString: true,
                                confirmButtonText: '确定',
                            });
                        }
                    });
                } else if (printerType === 2) {
                    this.$api.Ex.Order.printOrderByXpyun({ ids }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    });
                }
            });
        },
        orderLodopPrint(res, ids) {
            const { waybillTemplate } = res;
            const { data } = res;
            const templateList = [];
            data.forEach(item => {
                let tpl = waybillTemplate;
                Object.keys(item).forEach(key => {
                    const rex = new RegExp(`\\$\\{${key}\\}`, 'g');
                    const val = JSON.stringify(item[key]);
                    tpl = tpl.replace(rex, val.slice(1, val.length - 1));
                });
                templateList.push(tpl);
            });
            const resultTemplateList = [];
            templateList.forEach(item => {
                resultTemplateList.push(item.replace('LODOP.PRINT_INIT("");', ''));
            });
            /* const lodop = `LODOP.PRINT_INIT("");${templateList.join(
                'LODOP.NewPage();',
            )}LODOP.SET_PRINT_MODE('AUTO_CLOSE_PREWINDOW',1);`; */
            const lodop = `${resultTemplateList.join('LODOP.NewPage();')}`;
            this.$lodop.init(lodop).then(({ LODOP, CLODOP }) => {
                CLODOP.On_Return = (taskId, value) => {
                    if (value === 0) {
                        return;
                    }
                    this.$api.Ex.Order.updateOrderPrintStatus({ ids, status: 1 }).then(json => {
                        const res1 = json.data;
                        this.$message({
                            message: res1.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    });
                };
                LODOP.PREVIEW();
            });
        },
        /* -- 方法 -- */
        onSpanMethod({ columnIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (!columnIndex) {
                return [1, 1];
            }
            return [1, this.tableColumn];
        },
        onGoodsSpanMethod({ columnIndex, rowIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (columnIndex < 3) {
                return [1, 1];
            }
            if (!rowIndex) {
                return [100, 1];
            }
            return [0, 0];
        },
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.orderList({
                ...queryData,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: this.$t('pageKey978', '包装费'),
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '手续费',
                        value: item.paymentPoundage,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.initEstimateShippingTimeList();
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // 取消订单
        cancelOrder(row) {
            this.cancelDialog.formModel.id = row.id;
            if (row.goodsSendMode === 2 || row.goodsSendMode === 3 || row.goodsSendMode === 4) {
                this.cancelDialog.type = 1;
            } else {
                this.cancelDialog.type = 2;
                this.cancelDialog.formModel.cancelDesc = '其他';
            }
            this.cancelDialog.isVisible = true;
        },
        onCancelDialogConfirm() {
            const { cancelForm } = this.$refs;
            cancelForm.validate().then(() => {
                this.$api.Ex.Order.cancelOrder({
                    ...this.cancelDialog.formModel,
                    cancelDesc:
                        this.cancelDialog.formModel.cancelDesc === '其他'
                            ? this.cancelDialog.formModel.cancelDescOther
                            : this.cancelDialog.formModel.cancelDesc,
                    isSendMsg: this.cancelDialog.formModel.cancelDesc !== '其他',
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.cancelDialog.isVisible = false;
                });
            });
        },
        onCancelDialogCancel() {
            const { cancelForm } = this.$refs;
            cancelForm.resetFields();
            const { formModel } = this.cancelDialog;
            formModel.id = '';
            this.cancelDialog.isVisible = false;
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        initShopExpressList() {
            this.$api.Sp.ShopExpress.data().then(json => {
                const res = json.data.data;
                res.push({
                    expressId: -1,
                    expressName: '其他快递公司',
                });
                this.shopExpressList = res;
            });
        },
        initEstimateShippingTimeList() {
            this.$api.Ex.Order.getEstimateShippingTimeList({
                ...this.queryFormModel,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data.data;
                this.estimateShippingTimeList = res;
            });
        },
        // excel导出
        exportOrderExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            let presellEndTimeBegin = null;
            let presellEndTimeEnd = null;
            if (this.queryFormModel.presellEndTime != null && this.queryFormModel.presellEndTime.length === 2) {
                [presellEndTimeBegin, presellEndTimeEnd] = this.queryFormModel.presellEndTime;
            }
            let estimateShippingTimeBegin = null;
            let estimateShippingTimeEnd = null;
            if (
                this.queryFormModel.estimateShippingTime != null &&
                this.queryFormModel.estimateShippingTime.length === 2
            ) {
                [estimateShippingTimeBegin, estimateShippingTimeEnd] = this.queryFormModel.estimateShippingTime;
            }
            let confirmShipDateBegin = null;
            let confirmShipDateEnd = null;
            if (this.queryFormModel.confirmShipDate != null && this.queryFormModel.confirmShipDate.length === 2) {
                [confirmShipDateBegin, confirmShipDateEnd] = this.queryFormModel.confirmShipDate;
            }
            this.$http.href('/ex/order/exportOrderExcel', {
                ...this.queryFormModel,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
                orderBeginTime,
                orderEndTime,
                presellEndTimeBegin,
                presellEndTimeEnd,
                estimateShippingTimeBegin,
                estimateShippingTimeEnd,
                confirmShipDateBegin,
                confirmShipDateEnd,
                ids,
            });
        },
        exportGoodsStatExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let orderBeginTime = null;
            let orderEndTime = null;
            if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
            }
            let presellEndTimeBegin = null;
            let presellEndTimeEnd = null;
            if (this.queryFormModel.presellEndTime != null && this.queryFormModel.presellEndTime.length === 2) {
                [presellEndTimeBegin, presellEndTimeEnd] = this.queryFormModel.presellEndTime;
            }
            let estimateShippingTimeBegin = null;
            let estimateShippingTimeEnd = null;
            if (
                this.queryFormModel.estimateShippingTime != null &&
                this.queryFormModel.estimateShippingTime.length === 2
            ) {
                [estimateShippingTimeBegin, estimateShippingTimeEnd] = this.queryFormModel.estimateShippingTime;
            }
            let confirmShipDateBegin = null;
            let confirmShipDateEnd = null;
            if (this.queryFormModel.confirmShipDate != null && this.queryFormModel.confirmShipDate.length === 2) {
                [confirmShipDateBegin, confirmShipDateEnd] = this.queryFormModel.confirmShipDate;
            }
            this.$http.href('/ex/order/exportGoodsStatExcel', {
                ...this.queryFormModel,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
                orderBeginTime,
                orderEndTime,
                presellEndTimeBegin,
                presellEndTimeEnd,
                estimateShippingTimeBegin,
                estimateShippingTimeEnd,
                confirmShipDateBegin,
                confirmShipDateEnd,
                ids,
            });
        },
        // 平台留言
        orderComments(row) {
            this.orderCommentsDialog.formModel.id = row.id;
            this.orderCommentsDialog.formModel.orderComments = row.orderComments;
            this.orderCommentsDialog.isVisible = true;
        },
        onOrderCommentsDialogConfirm() {
            const { orderCommentsForm } = this.$refs;
            orderCommentsForm.validate().then(() => {
                this.$api.Ex.Order.editOrderComments({ ...this.orderCommentsDialog.formModel }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.orderCommentsDialog.isVisible = false;
                });
            });
        },
        onOrderCommentsDialogCancel() {
            const { orderCommentsForm } = this.$refs;
            orderCommentsForm.resetFields();
            const { formModel } = this.orderCommentsDialog;
            formModel.id = '';
            formModel.orderComments = '';
            this.orderCommentsDialog.isVisible = false;
        },
        onBatchSyncErpOrder(id) {
            if (!id) {
                const ids = this.currentTableSelect.reduce((prev, next) => {
                    prev.push(next.id);
                    return prev;
                }, []);
                if (ids.length === 0) {
                    this.$message({
                        message: '请选择订单',
                        type: 'error',
                    });
                    return;
                }
                id = ids.join(',');
            }
            this.$confirm('是否确定同步订单！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrder({ ids: id }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
        onBatchSyncErpOrderTrackingInfo() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择订单',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定同步订单物流信息！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单物流中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.Order.batchSyncErpOrderTrackingInfo({ ids: ids.join(',') }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                            // callback(action) {},
                        });
                    }
                });
            });
        },
        // 打印面单
        onLodopPrint() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length !== 0) {
                this.$confirm('是否确定打印所选订单！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Ex.Order.getOrderExpressId({ ids: ids.join(',') }).then(json => {
                        this.onLodopPrintDialog(json.data.data, ids.join(','));
                    });
                });
            } else {
                this.$confirm('是否确定打印查询订单！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Ex.Order.getOrderExpressId(this.queryFormModel).then(json => {
                        this.onLodopPrintDialog(json.data.data, null);
                    });
                });
            }
        },
        onLodopPrintDialog(expressId, ids) {
            this.$api.Sp.ShopExpress.getByExpressId({ expressId }).then(json => {
                this.lodopPrintDialog.templateList = JSON.parse(json.data.data.waybillTemplate);

                this.lodopPrintDialog.formModel.waybillTemplateExpressId = expressId;
                this.lodopPrintDialog.formModel.ids = ids;
                this.lodopPrintDialog.isVisible = true;
            });
        },
        onLodopPrintDialogConfirm() {
            const { lodopPrintForm } = this.$refs;
            lodopPrintForm.validate().then(() => {
                this.$api.Ex.Order.getOrderLodopPrintInfo({
                    ...this.queryFormModel,
                    ...this.lodopPrintDialog.formModel,
                }).then(json => {
                    this.lodopPrint(json.data.data);
                });
            });
        },
        onLodopPrintDialogCancel() {
            const { lodopPrintForm } = this.$refs;
            lodopPrintForm.resetFields();
            const { formModel } = this.lodopPrintDialog;
            formModel.ids = '';
            this.lodopPrintDialog.isVisible = false;
        },
        lodopPrint(data) {
            this.lodop = `LODOP.PRINT_INIT("");${data}LODOP.PREVIEW();`;
            const $Lodop = this.$refs.Lodop;
            this.$nextTick(() => {
                $Lodop.print();
            });
        },
        // 订单审核
        auditOrderBatch() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length !== 0) {
                this.auditOrder(ids.join(','));
            } else {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
            }
            this.auditDialog.isVisible = true;
        },
        auditOrder(ids) {
            this.auditDialog.formModel.ids = ids;
            this.auditDialog.isVisible = true;
        },
        onAuditDialogConfirm() {
            const { auditForm } = this.$refs;
            auditForm.validate().then(() => {
                this.$api.Ex.Order.auditOrder({
                    ...this.auditDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.auditDialog.isVisible = false;
                });
            });
        },
        onAuditDialogCancel() {
            const { auditForm } = this.$refs;
            auditForm.resetFields();
            const { formModel } = this.auditDialog;
            formModel.id = '';
            this.auditDialog.isVisible = false;
        },
        // 导出商品图片
        exportOrderGoodsImage() {
            this.$confirm('是否确定导出商品图片！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                let ids = '';
                if (this.currentTableSelect.length !== 0) {
                    const idList = [];
                    this.currentTableSelect.forEach(item => {
                        idList.push(item.id);
                    });
                    ids = idList.join(',');
                }
                let orderBeginTime = null;
                let orderEndTime = null;
                if (this.queryFormModel.orderTime != null && this.queryFormModel.orderTime.length === 2) {
                    [orderBeginTime, orderEndTime] = this.queryFormModel.orderTime;
                }
                this.$http.href('/ex/order/exportOrderGoodsImage', {
                    ...this.queryFormModel,
                    orderBeginTime,
                    orderEndTime,
                    ids,
                });
            });
        },
        // 售后备注
        orderLog(row) {
            this.orderLogDialog.formModel.id = row.id;
            this.orderLogDialog.isVisible = true;
        },
        onOrderLogDialogConfirm() {
            const { orderLogForm } = this.$refs;
            orderLogForm.validate().then(() => {
                this.$api.Ex.Order.addOrderLog({
                    ...this.orderLogDialog.formModel,
                    orderLog: `售后备注:${this.orderLogDialog.formModel.orderLog}`,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.onQuery(this.pagination);
                    this.orderLogDialog.isVisible = false;
                });
            });
        },
        onOrderLogDialogCancel() {
            const { orderLogForm } = this.$refs;
            orderLogForm.resetFields();
            const { formModel } = this.orderLogDialog;
            formModel.id = '';
            formModel.orderLog = '';
            this.orderLogDialog.isVisible = false;
        },
        // 送达签收
        completeOrder(row) {
            this.completeOrderDialog.formModel.id = row.id;
            this.completeOrderDialog.isVisible = true;
        },
        onCompleteOrderDialogConfirm() {
            const { completeOrderForm } = this.$refs;
            completeOrderForm.validate().then(() => {
                this.$api.Ex.Order.completeOrder({
                    ...this.completeOrderDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.completeOrderDialog.isVisible = false;
                });
            });
        },
        onCompleteOrderDialogCancel() {
            const { completeOrderForm } = this.$refs;
            completeOrderForm.resetFields();
            const { formModel } = this.completeOrderDialog;
            formModel.id = '';
            this.completeOrderDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.isPrivateCustom = 0;
        this.type = 1;
        this.initLevelList();
    },
};
</script>

<style lang="scss">
.order-table {
    td.el-table-column--selection {
        vertical-align: top;
    }

    td.cell-no-pa {
        &,
        > .cell {
            padding: 0;
        }
    }

    .table-ctrl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-button + .el-button {
            margin: 0;
        }
    }

    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding;
        background-color: $gray1;

        span + span {
            margin-left: $margin;
        }

        > .supplier {
            flex: 0 0 270px;
        }

        > .shipping-time {
            flex: 0 0 370px;
            text-align: right;
        }
    }

    .order-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: $padding;

        > .left {
            flex: 0.6 0.6 1%;
        }

        > .right {
            flex: 0.4 0.4 1%;
            text-align: right;

            > .inner {
                text-align: left;
                display: inline-block;
            }
        }

        .buyer-msg {
            @include text-line(1);
        }
    }
}
</style>
<style lang="scss" scoped>
.goods-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .image-list-wrap {
        flex: 0 0 auto;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .badge {
            display: inline-block;
            border-radius: 100em;
            background-color: $color-danger;
            color: #fff;
            line-height: 24px;
            min-width: 24px;
            text-align: center;
        }
    }
}
</style>
