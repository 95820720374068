<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>订单信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="订单编号:"
                                label-width="8em"
                            >
                                {{ orderImp.orderSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="订单来源:"
                                label-width="8em"
                            >
                                {{ orderImp.orderSourceName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="下单时间:"
                                label-width="8em"
                            >
                                {{ orderImp.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="付款时间:"
                                label-width="8em"
                            >
                                {{ orderImp.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="userData.appId === 1"
                        >
                            <el-form-item
                                label="商户:"
                                label-width="8em"
                            >
                                {{ orderImp.shopName }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="userData.appId === 1"
                        >
                            <el-form-item
                                label="供应商:"
                                label-width="8em"
                            >
                                {{ orderImp.supplierName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品类型:"
                                label-width="8em"
                            >
                                {{ orderImp.goodsTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="订单状态:"
                                label-width="8em"
                            >
                                {{ orderImp.orderStatusName }} <span v-if="orderImp.orderStatus === 99">({{ orderImp.cancelDesc }})</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="买家留言:"
                                label-width="8em"
                            >
                                {{ orderImp.buyerMsg }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>商品信息</span>
                            <el-button
                                size="mini"
                                type="success"
                                icon="el-icon-edit"
                                @click="editOrderDetail"
                            >
                                修改商品信息
                            </el-button>
                        </div>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="orderImp.orderDetailList"
                                style="width: 100%"
                                show-summary
                                :summary-method="getSummaries1"
                            >
                                <el-table-column
                                    label="商品图片"
                                    width="120"
                                >
                                    <template slot-scope="scope">
                                        <ImageList
                                            :data="scope.row.specImageUrl"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="goodsName"
                                    label="商品名称"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.goodsName }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="skuSn"
                                    label="商品编码"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="finalPrice"
                                    label="商品价格"
                                    min-width="50"
                                />
                                <el-table-column
                                    prop="quantity"
                                    label="数量"
                                    min-width="50"
                                />
                                <el-table-column
                                    prop="subtotalAmount"
                                    label="小计"
                                    min-width="50"
                                />
                            </el-table>
                        </el-form-item>
                        <el-form-item
                            label="商品总金额:"
                            label-width="8em"
                        >
                            {{ orderImp.goodsAmount }}
                        </el-form-item>
                        <el-form-item
                            label="商品总积分:"
                            label-width="8em"
                        >
                            {{ orderImp.goodsPointNum }}
                        </el-form-item>
                        <el-form-item
                            label="总运费:"
                            label-width="8em"
                        >
                            {{ orderImp.freight }}
                        </el-form-item>
                        <el-form-item
                            :label="$t('pageKey978', '包装费')+':'"
                            label-width="8em"
                        >
                            {{ orderImp.packingAmount }}
                        </el-form-item>
                        <el-form-item
                            label="优惠券金额:"
                            label-width="8em"
                        >
                            {{ orderImp.couponAmount }}
                        </el-form-item>
                        <el-form-item
                            label="积分抵扣金额:"
                            label-width="8em"
                        >
                            {{ orderImp.pointAmount }}
                        </el-form-item>
                        <el-form-item
                            label="满额减免金额:"
                            label-width="8em"
                        >
                            {{ orderImp.reduceAmount }}
                        </el-form-item>
                        <el-form-item
                            label="总优惠金额:"
                            label-width="8em"
                        >
                            {{ orderImp.totalDiscountAmount }}
                        </el-form-item>
                        <el-form-item
                            label="保费金额:"
                            label-width="8em"
                        >
                            {{ orderImp.goodsPremiumAmount }}
                        </el-form-item>
                        <el-form-item
                            label="手续费:"
                            label-width="8em"
                        >
                            {{ orderImp.paymentPoundage }}
                        </el-form-item>
                        <el-form-item
                            label="订单金额:"
                            label-width="8em"
                        >
                            {{ orderImp.payableAmount }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.extendFieldInfoList && orderImp.extendFieldInfoList.length != 0"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>扩展信息</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="orderImp.extendFieldInfoList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="label"
                                    label="字段名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="value"
                                    label="字段值"
                                    min-width="100"
                                />
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.shipMethod === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>送货上门</span>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人地址:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryRegionName }} {{ orderImp.deliveryAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="orderImp.goodsSendMode != 1 && orderImp.goodsSendMode != 4"
                        >
                            <el-form-item
                                label="身份证号:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="orderImp.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证正面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="orderImp.deliveryIdCardFrontUrl"
                                    :data="orderImp.deliveryIdCardFrontUrl || ''"
                                />
                                <template
                                    v-if="!orderImp.deliveryIdCardFrontUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="orderImp.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证反面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="orderImp.deliveryIdCardBackUrl"
                                    :data="orderImp.deliveryIdCardBackUrl || ''"
                                />
                                <template
                                    v-if="!orderImp.deliveryIdCardBackUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="orderImp.goodsSendMode == 4"
                        >
                            <el-form-item
                                label="付款人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.subscriberName }}
                            </el-form-item>
                            <el-form-item
                                label="付款人身份证号:"
                                label-width="8em"
                            >
                                {{ orderImp.subscriberIdCardNo }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.shipMethod === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>门店自取</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                label="门店名称:"
                                label-width="8em"
                            >
                                {{ orderImp.pickupStoreName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="门店地址:"
                                label-width="8em"
                            >
                                {{ orderImp.pickupStoreRegionName }} {{ orderImp.pickupStoreName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="自取日期:"
                                label-width="8em"
                            >
                                {{ orderImp.pickupDate | moment('YYYY-MM-DD') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="自取时间:"
                                label-width="8em"
                            >
                                {{ orderImp.pickupTime }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.shipMethod === 4 && orderImp.goodsType === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>本地配送</span>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人地址:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryRegionName }} {{ orderImp.deliveryAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="orderImp.goodsSendMode != 1 && orderImp.goodsSendMode != 4"
                        >
                            <el-form-item
                                label="身份证号:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="orderImp.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证正面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="orderImp.deliveryIdCardFrontUrl"
                                    :data="orderImp.deliveryIdCardFrontUrl || ''"
                                />
                                <template
                                    v-if="!orderImp.deliveryIdCardFrontUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="orderImp.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证反面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="orderImp.deliveryIdCardBackUrl"
                                    :data="orderImp.deliveryIdCardBackUrl || ''"
                                />
                                <template
                                    v-if="!orderImp.deliveryIdCardBackUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="orderImp.goodsSendMode == 4"
                        >
                            <el-form-item
                                label="付款人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.subscriberName }}
                            </el-form-item>
                            <el-form-item
                                label="付款人身份证号:"
                                label-width="8em"
                            >
                                {{ orderImp.subscriberIdCardNo }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.shipMethod === 8 && orderImp.goodsType === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>社区配送</span>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item
                                    label="门店名称:"
                                    label-width="8em"
                                >
                                    {{ orderImp.storeName }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="小区名称:"
                                    label-width="8em"
                                >
                                    {{ orderImp.stationName }}
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人楼层号:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryRegionAddress }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderImp.shipMethod === 16 && orderImp.goodsType === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>门店配送</span>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item
                                    label="门店名称:"
                                    label-width="8em"
                                >
                                    {{ orderImp.storeName }}
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人地址:"
                                label-width="8em"
                            >
                                {{ orderImp.deliveryRegionAddress }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
            </el-form>
            <div class="pa-t">
                <el-button
                    size="small"
                    type="primary"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </div>
        </template>

        <!-- 修改商品信息 -->
        <el-dialog
            title="修改商品信息"
            center
            width="1200px"
            :visible.sync="editOrderDetailDialog.isVisible"
            @closed="onEditOrderDetailDialogCancel"
        >
            <el-form
                ref="editOrderDetailForm"
                size="small"
                :model="editOrderDetailDialog.formModel"
                :rules="editOrderDetailDialog.formRules"
            >
                <el-form-item
                    label="商品信息"
                    label-width="8em"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="editOrderDetailDialog.formModel.orderDetailList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="goodsName"
                            label="商品名称"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.goodsName }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="skuSn"
                            label="商品编码"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <el-input
                                    v-model="scope.row.skuSn"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="finalPrice"
                            label="商品价格"
                            min-width="50"
                        />
                        <el-table-column
                            prop="quantity"
                            label="数量"
                            min-width="50"
                        />
                        <el-table-column
                            prop="subtotalAmount"
                            label="小计"
                            min-width="50"
                        />
                    </el-table>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditOrderDetailDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onEditOrderDetailDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrderDetail',
    mixins: [pagesMixin],
    data() {
        return {
            id: '',
            orderImp: {},
            editOrderDetailDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderDetailList: [],
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    methods: {
        init() {
            this.$api.Ex.OrderImp.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.orderImp = res.orderImp || {};
            });
        },
        getSummaries1(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index === 4 || index === 5) {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => Number.isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!Number.isNaN(value)) {
                                return prev + curr;
                            }
                            return prev;
                        }, 0);
                    }
                }
            });
            return sums;
        },
        // 修改订单信息
        editOrderDetail() {
            this.editOrderDetailDialog.formModel.id = this.id;
            this.editOrderDetailDialog.formModel.orderDetailList = this.orderImp.orderDetailList;
            this.editOrderDetailDialog.isVisible = true;
        },
        onEditOrderDetailDialogConfirm() {
            const { editOrderDetailForm } = this.$refs;
            editOrderDetailForm.validate().then(() => {
                this.$api.Ex.OrderImp.editOrderDetailInfo({
                    ...this.editOrderDetailDialog.formModel,
                    orderDetailListJson: JSON.stringify(this.editOrderDetailDialog.formModel.orderDetailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.editOrderDetailDialog.isVisible = false;
                });
            });
        },
        onEditOrderDetailDialogCancel() {
            const { editOrderDetailForm } = this.$refs;
            editOrderDetailForm.resetFields();
            const { formModel } = this.editOrderDetailDialog;
            formModel.id = '';
            this.editOrderDetailDialog.isVisible = false;
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    border-radius: 100em;
    background-color: $color-danger;
    color: #fff;
    line-height: 24px;
    min-width: 24px;
    text-align: center;
}
</style>
