<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待出库"
                    name="20"
                />
                <el-tab-pane
                    label="配送中"
                    name="30"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    label="收件人"
                >
                    <el-input
                        v-model="queryFormModel.deliveryConsignee"
                        placeholder="请输入收件人"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhone"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderSource"
                    label="订单来源"
                >
                    <dictionaries-picker
                        type="orderSource"
                        v-model="queryFormModel.orderSource"
                    />
                </el-form-item>
                <el-form-item
                    prop="shipMethod"
                    label="发货方式"
                >
                    <dictionaries-picker
                        type="shipMethod"
                        v-model="queryFormModel.shipMethod"
                    />
                </el-form-item>
                <el-form-item
                    prop="isOrderException"
                    label="是否异常"
                >
                    <el-select
                        v-model="queryFormModel.isOrderException"
                        placeholder="请选择"
                    >
                        <el-option
                            key="1"
                            label="是"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <!--                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onOrderImport()"
                        v-if="userData.appId === 2"
                    >
                        微盟云订单导入
                    </el-button>-->
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onSyncOrder()"
                    >
                        同步订单
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onSyncWeimobcloudSkuInfo()"
                        v-if="userData.shop.isSyncWeimobcloud"
                    >
                        同步微盟云商品编码
                    </el-button>

                </el-button-group>
            </div>

            <!--订单列表-->
            <el-table
                class="order-table"
                ref="table"
                stripe
                border
                size="small"
                :data="tableData"
                :span-method="onSpanMethod"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    class-name="cell-no-pa"
                    label="商品"
                >
                    <template slot-scope="order">
                        <div class="order-header">
                            <div>
                                <span>下单时间：{{ order.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}</span>
                                <span>订单号：{{ order.row.orderSn }}</span>
                            </div>
                            <div
                                class="supplier"
                                v-if="order.row.supplierName">
                                供应商：{{ order.row.supplierName }}
                            </div>
                            <div class="shipping-time">
                                <template v-if="order.row.estimateShippingTime">
                                    预计发货时间：{{ order.row.estimateShippingTime | moment }}&nbsp;&nbsp;
                                </template>
                                <template v-if="order.row.confirmShipDate">
                                    配送时间：{{ order.row.confirmShipDate | moment }} {{ order.row.confirmShipTime }}&nbsp;&nbsp;
                                </template>
                            </div>
                        </div>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                            :show-header="false"
                            :span-method="onGoodsSpanMethod"
                        >
                            <el-table-column label="商品">
                                <div
                                    class="goods-wrap"
                                    slot-scope="scope"
                                >
                                    <ImageList :data="scope.row.specImageUrl"/>
                                    <div class="body">
                                        <div class="label">
                                            {{ scope.row.goodsName }}({{ scope.row.skuSn }})
                                        </div>
                                        <div class="spec">
                                            {{ scope.row.specDesc }}
                                        </div>
                                    </div>
                                </div>
                            </el-table-column>
                            <el-table-column
                                prop="finalPrice"
                                label="单价"
                                width="100"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                width="100"
                            />
                            <el-table-column
                                label="订单金额"
                                width="100"
                            >
                                <template v-slot>
                                    <el-popover
                                        trigger="hover"
                                    >
                                        <el-table
                                            :show-header="false"
                                            :data="order.row.amountInfoList"
                                        >
                                            <el-table-column
                                                width="150px"
                                                property="label"
                                            />
                                            <el-table-column
                                                width="100px"
                                                property="value"
                                            />
                                        </el-table>
                                        <span
                                            slot="reference"
                                            class="tc-blue"
                                        >{{ order.row.payableAmount }}</span>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                width="100"
                            >
                                <div class="table-ctrl">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="$router.push('/order/orderImp/orderImpDetail/' + order.row.id)"
                                    >
                                        查看
                                    </el-button>
                                </div>
                            </el-table-column>
                        </el-table>
                        <div class="order-footer">
                            <div class="left">
                                <div class="address">
                                    收件信息：{{ order.row.deliveryConsignee }} <span class="tc-primary">{{ order.row.deliveryPhone }}</span> {{ order.row.deliveryRegionAddress }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="买家留言"
                                >
                                    买家留言：{{ order.row.buyerMsg }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="平台留言"
                                >
                                    平台留言：{{ order.row.orderComments }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    v-if="order.row.orderTagList && order.row.orderTagList.length !== 0"
                                >
                                    <el-tag
                                        v-for="item in order.row.orderTagList"
                                        :key="item"
                                        type="danger">{{ item }}
                                    </el-tag>
                                </div>
                            </div>
                            <div class="right">
                                <div class="inner">
                                    <div
                                        v-if="order.row.storeName"
                                        class="distributor"
                                    >
                                        门店：
                                        {{ order.row.storeName }}({{ order.row.storeSn }})
                                    </div>
                                    <div
                                        v-if="order.row.stationName"
                                        class="distributor"
                                    >
                                        小区：
                                        {{ order.row.stationName }}
                                    </div>
                                    <div
                                        v-if="order.row.extendFieldInfoList && order.row.extendFieldInfoList.length != 0"
                                        class="extra"
                                    >
                                        扩展信息：
                                        <el-popover
                                            trigger="hover"
                                        >
                                            <el-table :data="order.row.extendFieldInfoList">
                                                <el-table-column
                                                    width="150px"
                                                    property="label"
                                                    label="字段名称"
                                                />
                                                <el-table-column
                                                    width="200px"
                                                    property="value"
                                                    label="字段值"
                                                />
                                            </el-table>
                                            <span
                                                slot="reference"
                                                class="tc-blue"
                                            >{{ order.row.extendFieldInfoList[0].label }}:{{ order.row.extendFieldInfoList[0].value }}</span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    label="单价"
                />
                <el-table-column
                    width="100"
                    label="数量"
                />
                <el-table-column
                    width="100"
                    label="订单金额"
                />
                <el-table-column
                    width="100"
                    label="操作"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--订单导入-->
        <el-dialog
            title="订单导入"
            center
            width="600px"
            :visible.sync="orderImportDialog.isVisible"
            @closed="onOrderImportDialogCancel"
        >
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    disabled
                >
                    模板下载
                </el-button>
                <el-upload
                    class="upload-demo dp-ib ma-l"
                    action="/ex/orderImp/batchCreateOrderByExcel"
                    :data="{type:1}"
                    :show-file-list="false"
                    accept=".csv"
                    :on-success="onOrderImportSuccess"
                    :on-error="onOrderImportError"
                >
                    <el-button
                        size="small"
                        type="primary"
                    >
                        点击上传
                    </el-button>
                </el-upload>
            </div>
        </el-dialog>
        <!--同步订单-->
        <el-dialog
            title="同步订单"
            center
            width="600px"
            :visible.sync="syncOrderDialog.isVisible"
            @closed="onSyncOrderDialogCancel"
        >
            <el-form
                ref="syncOrderForm"
                size="small"
                :model="syncOrderDialog.formModel"
                :rules="syncOrderDialog.formRules"
            >
                <el-form-item
                    prop="syncTime"
                    label-width="6em"
                    label="更新时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="syncOrderDialog.formModel.syncTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSyncOrderDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSyncOrderDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <Lodop
            ref="Lodop"
            v-model="lodop"
        />
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Order',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                orderStatus: '20',
                orderSource: '',
                shipMethod: '',
                orderTime: '',
                goodsSendMode: '',
                isOrderException: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
            lodop: '',
            orderImportDialog: {
                // 对话框显示
                isVisible: false,
            },
            // 同步订单
            syncOrderDialog: {
                // 对话框显示
                isVisible: false,
                loading: null,
                // 表单数据
                formModel: {
                    ids: '',
                    syncTime: [],
                },
                // 表单校验规则
                formRules: {
                    customerId: {
                        required: true,
                        message: '请选择客户',
                    },
                    syncTime: {
                        type: 'array',
                        required: true,
                        message: '请选择同步时间',
                        trigger: 'blur',
                    },
                },
            },
        };
    },
    computed: {
        tableColumn() {
            return this.$refs.table.columns.length - 1;
        },
    },
    methods: {
        /* -- 方法 -- */
        onSpanMethod({ columnIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (!columnIndex) {
                return [1, 1];
            }
            return [1, this.tableColumn];
        },
        onGoodsSpanMethod({ columnIndex, rowIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (columnIndex < 3) {
                return [1, 1];
            }
            if (!rowIndex) {
                return [100, 1];
            }
            return [0, 0];
        },
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.OrderImp.orderList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: this.$t('pageKey978', '包装费'),
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // 订单导入
        onOrderImport() {
            this.orderImportDialog.isVisible = true;
        },
        onOrderImportDialogCancel() {
            this.orderImportDialog.isVisible = false;
        },
        onOrderImportSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onOrderImportError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        // 同步订单
        onSyncOrder() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length !== 0) {
                this.$confirm('是否确定要重新同步选择订单！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Ex.OrderImp.syncOrder({
                        ids: ids.join(','),
                    }).then(json => {
                        const res = json.data;
                        if (res.success) {
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            });
                            this.onQuery(this.pagination);
                        } else {
                            this.$alert(res.msg, '温馨提示', {
                                type: 'error',
                            });
                        }
                    });
                });
            } else {
                this.syncOrderDialog.isVisible = true;
                this.$nextTick(() => {
                    const { syncOrderForm } = this.$refs;
                    syncOrderForm.resetFields();
                });
            }
        },
        onSyncOrderDialogConfirm() {
            const { syncOrderForm } = this.$refs;
            syncOrderForm.validate().then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '同步订单',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Ex.OrderImp.syncOrder({
                    ...this.syncOrderDialog.formModel,
                }).then(json => {
                    this.loading.close();
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                        this.syncOrderDialog.isVisible = false;
                    } else {
                        this.$alert(res.msg, '温馨提示', {
                            type: 'error',
                        });
                    }
                });
            });
        },
        onSyncOrderDialogCancel() {
            this.syncOrderDialog.isVisible = false;
        },
        onSyncWeimobcloudSkuInfo() {
            this.$confirm('是否确定要同步微盟云商品编码！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.OrderImp.syncWeimobcloudSkuInfo().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
};
</script>

<style lang="scss">
.order-table {
    td.el-table-column--selection {
        vertical-align: top;
    }

    td.cell-no-pa {
        &,
        > .cell {
            padding: 0;
        }
    }

    .table-ctrl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-button + .el-button {
            margin: 0;
        }
    }

    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding;
        background-color: $gray1;

        span + span {
            margin-left: $margin;
        }

        > .supplier {
            flex: 0 0 270px;
        }

        > .shipping-time {
            flex: 0 0 370px;
            text-align: right;
        }
    }

    .order-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: $padding;

        > .left {
            flex: 0.6 0.6 1%;
        }

        > .right {
            flex: 0.4 0.4 1%;
            text-align: right;

            > .inner {
                text-align: left;
                display: inline-block;
            }
        }

        .buyer-msg {
            @include text-line(1);
        }
    }
}
</style>
<style lang="scss" scoped>
.goods-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .image-list-wrap {
        flex: 0 0 auto;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .badge {
            display: inline-block;
            border-radius: 100em;
            background-color: $color-danger;
            color: #fff;
            line-height: 24px;
            min-width: 24px;
            text-align: center;
        }
    }
}
</style>
