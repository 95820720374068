<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="shippingTime"
                    label="发货时间"
                >
                    <el-date-picker
                        v-model="queryFormModel.shippingTime"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderExcel"
                    >
                        订单导出
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="交易单号"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentSn"
                    label="商户单号"
                    min-width="100"
                />
                <el-table-column
                    prop="mchId"
                    label="商户号"
                    min-width="100"
                />
                <el-table-column
                    prop="shipMethodName"
                    label="发货方式"
                    min-width="100"
                />
                <el-table-column
                    prop="shipModeName"
                    label="发货模式"
                    min-width="100"
                />
                <el-table-column
                    prop="expressName"
                    label="快递公司"
                    min-width="100"
                />
                <el-table-column
                    prop="trackingNumber"
                    label="快递单号（多个快递单使用;分隔）"
                    min-width="100"
                />
                <el-table-column
                    prop="isComShip"
                    label="是否完成发货"
                    min-width="100"
                />
                <el-table-column
                    prop="isReShip"
                    label="是否重新发货"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsInfo"
                    label="商品信息"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                shippingTime: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.findWeixinShipOrderList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        exportOrderExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            let shippingTimeBegin = null;
            let shippingTimeEnd = null;
            if (this.queryFormModel.shippingTime != null && this.queryFormModel.shippingTime.length === 2) {
                [shippingTimeBegin, shippingTimeEnd] = this.queryFormModel.shippingTime;
            }
            this.$http.href('/ex/order/exportWeixinShipOrderListExcel', {
                ...this.queryFormModel,
                shippingTimeBegin,
                shippingTimeEnd,
                ids,
            });
        },
    },
};
</script>

<style lang="scss">
</style>
